import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Light Show Creator for <span className="text-color-primary">Tesla</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                One-click make custom light show for Tesla vehicles based on music rhythm.
              </p>
            </div>
            <div className="reveal-from-bottom" data-reveal-delay="600">
              <ButtonGroup>
                <a href="https://apps.apple.com/us/app/light-show-creator-for-tesla/id6446385602" target="_blank">
                  <img src={require('./../../assets/images/app-store-badge.png')} width="180" alt="app store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.coding1024.tslshow" target="_blank">
                  <img src={require('./../../assets/images/google-play-badge.png')} width="180" alt="google play" />
                </a>
                {/* <a href="https://www.microsoft.com/store/productId/9PL28B7M856D" target="_blank">
                  <img src={require('./../../assets/images/windows-store-badge.png')} width="180" alt="microsoft" />
                </a> */}
                {/* <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                  </Button> */}
              </ButtonGroup>
              <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
                <a
                  data-video="https://www.youtube.com/embed/eTrOtoUqdtE?autoplay=1&mute=1"
                  href="#0"
                  aria-controls="video-modal"
                  onClick={openModal}
                >
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/video-placeholder.png')}
                    alt="Hero"
                    width={896}
                    height={504} />
                </a>
              </div>
              <Modal
                id="video-modal"
                show={videoModalActive}
                handleClose={closeModal}
                video="https://www.youtube.com/embed/eTrOtoUqdtE?autoplay=1&mute=1"
                videoTag="iframe" />
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;